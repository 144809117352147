import { createApp } from 'vue'
import App from './bundles/InterfaceBundle/components/App.vue'
import runtime from 'serviceworker-webpack-plugin/lib/runtime'

if('serviceWorker' in navigator) runtime.register()
const app = createApp(App)
app.mount('#app')

/*[[AUTOMATIC_IMPORTS]]*/
import './bundles/AuthBundle/AuthBundle.js'
import './bundles/DocumentBundle/DocumentBundle.js'
import './bundles/FileBundle/FileBundle.js'
import './bundles/FlowBundle/FlowBundle.js'
import './bundles/FormBundle/FormBundle.js'
import './bundles/ImportBundle/ImportBundle.js'
import './bundles/InstallBundle/InstallBundle.js'
import './bundles/InterfaceBundle/InterfaceBundle.js'
import './bundles/LogBundle/LogBundle.js'
import './bundles/ModelBundle/ModelBundle.js'
import './bundles/ScriptBundle/ScriptBundle.js'


export const Container = app
